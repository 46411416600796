import { Fragment } from 'react';

import Image from 'next/image';

import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';

import useSWRInfinite from 'swr/infinite';

import { NextLinkComposed } from './Link';


const Notifications = ({ closeNotifications }) => {
  const getKey = (index) => {
    return `/api/v2/user-notifications/?page=${index + 1}`
  }

  const { data, error, size, setSize } = useSWRInfinite(getKey, null, { refreshInterval: 1000 * 15 });
  // TODO handle error

  const results = data ? [].concat(...data) : [];
  const isEmpty = data?.[0]?.length === 0;
  const dataLength = data ? data.length : 0;
  const isLoading = size !== dataLength;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < 10);

  return (
    <Box
      id="scrollableNotifications"
      sx={{
        overflow: "auto",
        width: {
          xs: "calc(100vw - 10px)",
          sm: 420,
        },
        maxHeight: "calc(100vh - 72px)",
        bgcolor: 'white',
        p: 0,
        mb: 0
      }}
    >
      <Box color="primary.light" p={2} fontSize="h6.fontSize">Notifications</Box>

      {results.map((notification) => (
        <Fragment
          key={notification.id}
        >
          <Divider />
          <CardActionArea
            component={NextLinkComposed}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              py: 1,
              px: 2,
              bgcolor: notification.read ? '#efefef' : 'white'
            }}
            to={{ pathname: `/dash/my-chats/${notification.channel_pk}/`}}
            onClick={() => closeNotifications()}
            // What if notification is unread
          >
            <Avatar
              src={notification.avatar}
              sx={{
                width: 42,
                height: 42,
                fontSize: 16
              }}>{ notification.initials }</Avatar>
            <Box pl={2}>
              <Typography variant="body2">{ notification.notification_text }</Typography>
              <Box fontSize={12} color="text.secondary">{ notification.timesince }</Box>
            </Box>
          </CardActionArea>
        </Fragment>
      ))}

      {isEmpty && !isLoading &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 200
          }}
        >
          <Image width={101} height={85} src="/img/illustrations/service-illustration-dark-mode.png" alt="Send estimates" />
          <Typography variant="body1" sx={{ mt: 3 }}>No notifications yet ...</Typography>
          <Typography
            variant="body2" 
            color="text.secondary"
          >Create a job to receive estimates</Typography>
        </Box>
      }

      {dataLength === 0 && isLoading && 
        <Box
          sx={{
            my: 2,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      }
      {dataLength !== 0 &&
        <Box display="flex" justifyContent="center" bgcolor="#efefef">
          {!isReachingEnd && !isLoading &&
            <Button
              color="primary"
              sx={{ my: 1 }}
              onClick={() => setSize(size + 1)}
            >Load More</Button>
          }
          {isLoading && <CircularProgress sx={{ my: 1 }} />}
        </Box>
      }
    </Box>
  );
}

export default Notifications;

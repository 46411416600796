import { useState } from 'react';

import dynamic from 'next/dynamic';

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Link as MuiLink,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import Logout from '@mui/icons-material/Logout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';

import MBLogo from './MBLogo';
import Notifications from './Notifications';

import Link, { NextLinkComposed } from './Link';
import useAuth from '../contexts/auth.js';


const DynamicAuthDialog = dynamic(() => import ('./auth/AuthDialog'));
const DynamicSearchEstimates = dynamic(() => import ('./SearchEstimates'));
const DynamicCustomSearch = dynamic(() => import ('./CustomSearch'));


const WhiteMenuItem = styled(MenuItem)({
  color: 'white'
})


const TopNav = props => {
  
  const color = props.color ? props.color : 'primary';

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [authOpened, setAuthOpened] = useState(false);
  const [anchorUser, setAnchorUser] = useState(null);

  const { user, loading, logout } = useAuth();

  const openUser = Boolean(anchorUser);

  const menuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchOpen = () => {
    setOpenSearch(true);
    menuClose();
  };
  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const openAuth = () => {
    setAuthOpened(true);
  };

  const closeAuth = () => {
    setAuthOpened(false);
  };

  const userOpen = (event) => {
    setAnchorUser(event.currentTarget);
  };

  const userClose = () => {
    setAnchorUser(null);
  };

  const [anchorNotifications, setAnchorNotifications] = useState(null);
  const notificationsMenuOpen = Boolean(anchorNotifications);

  const openNotifications = (event) => {
    setAnchorNotifications(event.currentTarget);
  };

  const closeNotifications = () => {
    setAnchorNotifications(null);
  };

  return (
    <AppBar id="topNav" position="static" color={color} elevation={0}>
      <Toolbar>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Link href="/" sx={{ pt: 0.5 }}>
            <MBLogo />
          </Link>

          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex'
              },
              alignItems: 'center',
              '& > *': {
                m: {
                  md: '8px !important',
                  lg: '12px !important',
                },
                fontSize: {
                  md: 'body2.fontSize',
                  lg: 'body1.fontSize',
                }
              },
            }}
          >
            <Link
              href="/request-quotes/"
              underline="hover"
              color="white"
            >Get Quotes</Link>

            <Link
              href="/best-mechanics/"
              underline="hover"
              color="white"
            >Find a Mechanic</Link>

            <MuiLink
              onClick={() => handleClickOpen()}
              underline="hover"
              color="white"
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >Cost Estimate</MuiLink>

            <Link
              color="secondary"
              href="/write-review/"
              underline="hover"
            >Write Review</Link>

            <IconButton
              aria-label="Search"
              sx={{ color: 'white' }}
              onClick={() => handleSearchOpen()}
              size="large">
              <SearchIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex'
              },
              alignItems: 'center',
              minHeight: 80,
              // minWidth: 220,
            }}
          >
            {user == null ?
              <>
                <Link
                  color="white"
                  href="/for-mechanics/"
                  underline="hover"
                  variant="body2"
                >I&apos;m a Mechanic</Link>
                <Button
                  onClick={openAuth}
                  variant="contained" 
                  color="secondary" 
                  disabled={loading}
                  sx={{
                    ml: {
                      md: 1,
                      lg: 2
                    }
                  }}
                >Login</Button>
              </> :
              <>
                {user.is_business &&
                  <Button
                    color="info"
                    variant="contained"
                    startIcon={<DeviceHubIcon />}
                    href="/hub/"
                  >Workshop Hub</Button>
                }

                <IconButton
                  onClick={openNotifications}
                  sx={{
                    color: 'white'
                  }}
                  size="large"
                >
                  <Badge badgeContent={user.unread_notifications} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>

                <Menu
                  anchorEl={anchorNotifications}
                  open={notificationsMenuOpen}
                  onClose={closeNotifications}
                  onClick={closeNotifications}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      // mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 18,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                      py: 0
                    }
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  sx={{

                  }}
                  MenuListProps={{
                    sx: {
                      py: 0
                    }
                  }}
                >
                  <Notifications closeNotifications={closeNotifications} />
                </Menu>

                <Tooltip title={`${user.first_name} ${user.last_name}`} aria-label={`${user.first_name} ${user.last_name}`}>
                  <IconButton onClick={userOpen} size="large">
                    <Avatar
                      alt={user.first_name}
                      src={user.picture}
                      sx={{
                        bgcolor: 'primary.light',
                        color: 'white',
                      }}
                    >{user.initials}</Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  open={openUser}
                  anchorEl={anchorUser}
                  onClose={userClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      // mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 28,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                      py: 0
                    }
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  sx={{

                  }}
                  MenuListProps={{
                    sx: {
                      py: 0
                    }
                  }}
                >
                  <MenuItem
                    sx={{
                      minWidth: 200
                    }}
                    component={NextLinkComposed}
                    to={{ pathname: '/dash/profile/' }}
                  >
                    <Avatar
                      sx={{ bgcolor: 'primary.light' }}
                      alt={user.first_name}
                      src={user.picture}
                    >{user.initials}</Avatar> <span>{user.first_name} {user.last_name}</span>
                  </MenuItem>

                  {user.is_business ?
                    <MenuItem
                      component="a"
                      href="/hub/"
                    >
                      <Avatar sx={{ bgcolor: 'primary.light' }}>
                        <AutorenewIcon />
                      </Avatar> Switch to Business
                    </MenuItem> :
                    <MenuItem
                      component="a"
                      href="/dash/"
                    >
                      <Avatar sx={{ bgcolor: 'secondary.main' }}>
                        <DashboardIcon />
                      </Avatar> My Dashboard
                    </MenuItem>
                  }
                  <Divider />

                  <MenuItem
                    component={NextLinkComposed}
                    to={{ pathname: '/dash/' }}
                  >
                    <ListItemIcon>
                      <DirectionsCarIcon fontSize="small" />
                    </ListItemIcon>
                    My Cars
                  </MenuItem>
                  <MenuItem
                    component={NextLinkComposed}
                    to={{ pathname: '/dash/profile/' }}
                  >
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    My Profile
                  </MenuItem>
                  <MenuItem onClick={() => logout()}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            }
          </Box>

          <Box
            sx={{
              display: {
                xs: 'block',
                md: 'none'
              }
            }}>
            {user == null ?
              <IconButton
                onClick={menuClick}
                sx={{
                  color: 'white',
                  mr: -2
                }}
                aria-label="Menu Button"
                size="large">
                <MoreVertIcon />
              </IconButton> :
              <IconButton
                onClick={menuClick}
                sx={{
                  mr: -2
                }}
              >
                <Avatar
                  alt={user.first_name}
                  src={user.picture}
                  sx={{
                    bgcolor: 'primary.light',
                    color: 'white',
                    width: 40,
                    height: 40,
                  }}
                >{user.initials}</Avatar>
              </IconButton>
            }

            <Menu
              id="main-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={menuClose}
              sx={{
                '& > .MuiMenu-paper': {
                  backgroundColor: theme => theme.palette.primary.dark,
                  top: "48px !important",
                  width: "300px",
                },
              }}
            >
              <WhiteMenuItem
                onClick={() => handleSearchOpen()}
                component={Button}
                endIcon={<SearchIcon />}
              >
                Search
              </WhiteMenuItem>
              <Divider sx={{ bgcolor: '#ededed' }} />
              <MenuItem component={Button} sx={{ color: "secondary.main" }} href="/request-quotes/">Get Quotes</MenuItem>
              <WhiteMenuItem component="a" href="/write-review/">Write Review</WhiteMenuItem>
              <WhiteMenuItem component={Button} href="/best-mechanics/">Find a Mechanic</WhiteMenuItem>
              <WhiteMenuItem component={Button} href="/cost-estimates/">Cost Estimates</WhiteMenuItem>
              <Divider sx={{ bgcolor: '#ededed' }} />
              {user == null ?
                <div>
                  <WhiteMenuItem component={Button} color="secondary" href="/for-mechanics/">I’m a Mechanic</WhiteMenuItem>
                  <WhiteMenuItem component={Button} href="/contact-us/">Contact Us</WhiteMenuItem>
                  <MenuItem component={Button} sx={{ color: "secondary.main" }} onClick={openAuth}>Login</MenuItem>
                </div> :
                <div>
                  <WhiteMenuItem component={Button} color="secondary">{user.first_name} {user.last_name} ({user.email})</WhiteMenuItem>
                  {user.is_business && <WhiteMenuItem component={Button} href="/hub/">Workshop Hub</WhiteMenuItem>}
                  <WhiteMenuItem component={Button} href="/dash/">My Dashboard</WhiteMenuItem>
                  <WhiteMenuItem component={Button} onClick={logout}>Logout</WhiteMenuItem>
                </div>
              }
            </Menu>
          </Box>
        </Box>
      </Toolbar>

      <DynamicSearchEstimates open={open} handleClose={handleClose} />
      <DynamicCustomSearch open={openSearch} handleClose={handleSearchClose} />

      <DynamicAuthDialog open={authOpened} handleClose={closeAuth} />
    </AppBar>
  );
}

export default TopNav;
